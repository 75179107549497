/*
 * Copyright 2024 NXP
 */

"use client";

import React, { useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { usePathname, useSearchParams } from "next/navigation";
import { GetGqlErrorsQuery } from "@/src/lib/graphql";
import WholePageError from "@/src/components/ErrorBoundary/WholePageError/WholePageError";

const ApolloErrorsHandler = ({ children }: { children: React.ReactNode }) => {
  const ac = useApolloClient();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { data } = useQuery(GetGqlErrorsQuery);

  useEffect(() => {
    ac.cache.evict({ id: "ROOT_QUERY", fieldName: "gqlErrors" });
  }, [pathname, searchParams]);

  return data ? <WholePageError {...data} /> : children;
};

export default ApolloErrorsHandler;
