/*
 * Copyright 2024 NXP
 */

"use client";

import styles from "@/src/components/styles";
import { Box, Toolbar } from "@mui/material";
import Footer from "@/src/components/layout/Footer/Footer";
import React from "react";
import DrawerStyled from "../DrawerStyled/DrawerStyled";
import ApolloErrorsHandler from "@/src/components/Misc/ApolloErrorsHandler";

interface MainSectionProps {
  withDrawer?: boolean;
  withPadding?: boolean;
  children: React.ReactNode;
}

const MainSection = (props: MainSectionProps) => {
  const { withDrawer = false, withPadding = false, children } = props;
  return (
    <>
      {withDrawer && <DrawerStyled />}
      <Box
        sx={styles.layoutInnerContainer(withDrawer || withPadding)}
        component={"main"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Toolbar variant={"dense"} />
        <Box flex={1}>
          <ApolloErrorsHandler>{children}</ApolloErrorsHandler>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default MainSection;
