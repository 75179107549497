/*
 * Copyright 2023-2024 NXP
 */

import React, { ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import styles from "@/src/components/layout/DrawerStyled/styles";
import DrawerSection from "@/src/components/layout/DrawerStyled/DrawerSection";
import { IconButton } from "@mui/material";
import KeyboardDoubleArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowLeftTwoTone";
import KeyboardDoubleArrowRightTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowRightTwoTone";
import DrawerBase from "@/src/components/layout/DrawerStyled/DrawerBase";
import { useGlobalContext } from "@/src/contexts/GlobalContext";
import { useAuthContext } from "@/src/contexts/authContext";
import { useTranslations } from "next-intl";
import HouseIcon from "@mui/icons-material/House";
import MemoryTwoToneIcon from "@mui/icons-material/MemoryTwoTone";
import ViewModuleTwoToneIcon from "@mui/icons-material/ViewModuleTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import TuneIcon from "@mui/icons-material/Tune";
import Image from "next/image";
import MCUXpressoIcon from "@/public/images/MCUXpressoIDE.png";
import MCUXpressoVSCodeIcon from "@/public/images/MCUXpressoVSCodeIcon.png";
import MCUXpressoSecureProvisioningToolIcon from "@/public/images/MCUXpressoSecureProvisioningTool.png";
import MCUXpressoConfigToolsIcon from "@/public/images/MCUXpressoConfigTools.png";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import QuestionMarkTwoToneIcon from "@mui/icons-material/QuestionMarkTwoTone";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import LinkTwoToneIcon from "@mui/icons-material/LinkTwoTone";
import MeetingRoomTwoTone from "@mui/icons-material/MeetingRoomTwoTone";
import { DRAWER_WIDTH } from "@/src/components/layout/DrawerStyled/helpers";
import EbhubIcon from "@/public/images/ebhub.ico";
import ACHIcon from "@/public/images/ach.ico";
import FilterListIcon from "@mui/icons-material/FilterList";

export interface DrawerLinkType {
  url: string;
  title: string;
  icon: ReactElement;
  admin?: boolean;
  offset?: boolean;
  target?: string;
  active?: boolean;
  ignoreLocale?: boolean;
}

const DrawerStyled = () => {
  const [open, setOpen] = useState(
    window.localStorage.getItem("menuCollapsed") !== "true",
  );

  const toggleCollapseHandler = () => {
    setOpen((prevOpen) => {
      window.localStorage.setItem("menuCollapsed", prevOpen ? "true" : "false");
      return !prevOpen;
    });
  };

  const auth = useAuthContext();
  const globalCtx = useGlobalContext();
  const topMessage = globalCtx.topMessage;

  // FIXME refactor to common way via user endpoints from BE ? remove hard coded urls
  const hideConditions = {
    "/filtering-criteria-links": !auth.user?.hasCriteriaLinksAccess,
    "/mcuxadmin": !auth.user?.myUser?.admin,
    "/backdoor": !auth.user?.hasBackdoorAccess,
  };

  const t = useTranslations("DrawerStyled");

  const buildSDKLinks: DrawerLinkType[] = [
    {
      url: "/dashboard",
      title: t("dashboard"),
      icon: <HouseIcon />,
    },
    {
      url: "/select",
      title: t("select"),
      icon: <MemoryTwoToneIcon />,
      active: globalCtx.criteria.isWorkflowActive("hw"),
    },
    {
      url: "/filters",
      title: t("filters"),
      icon: <FilterListIcon />,
      active: globalCtx.criteria.areOtherWorkflowsActive(),
    },
    {
      url: "/select/middleware",
      title: t("middleware"),
      icon: <ViewModuleTwoToneIcon />,
      offset: true,
      active: globalCtx.criteria.isWorkflowActive("middleware"),
    },
    {
      url: "/select/examples",
      title: t("examples"),
      icon: <AccountTreeTwoToneIcon />,
      offset: true,
      active: globalCtx.criteria.isWorkflowActive("examples"),
    },
    {
      url: "/select/toolchains",
      title: t("toolchains"),
      icon: <HandymanTwoToneIcon />,
      offset: true,
      active: globalCtx.criteria.isWorkflowActive("toolchain"),
    },
    {
      url: "/select/device-parametrics",
      title: t("parametrics"),
      icon: <TuneIcon />,
      offset: true,
      active: globalCtx.criteria.isWorkflowActive("parametric"),
    },
  ];

  const downloadsLinks: DrawerLinkType[] = [
    {
      url: "https://www.nxp.com/mcuxpresso/ide",
      title: "MCUXpresso IDE",
      icon: (
        <Image
          alt="MCUXpresso IDE icon"
          width={24}
          height={24}
          src={MCUXpressoIcon.src}
        />
      ),
      target: "_blank",
    },
    {
      url: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-for-visual-studio-code:MCUXPRESSO-VSC?tid=vanMCUXPRESSO-VSC",
      title: "MCUXpresso for VS Code",
      icon: (
        <Image
          alt={t("vsCode")}
          width={24}
          height={24}
          src={MCUXpressoVSCodeIcon.src}
        />
      ),
      target: "_blank",
    },
    {
      url: "https://www.nxp.com/mcuxpresso/secure",
      title: t("secureProv"),
      icon: (
        <Image
          alt="secure privisioning tool icon"
          width={24}
          height={24}
          src={MCUXpressoSecureProvisioningToolIcon.src}
        />
      ),
      target: "_blank",
    },
    {
      url: "https://www.nxp.com/mcuxpresso/config",
      title: t("configTools"),
      icon: (
        <Image
          alt="MCUXpresso Config Tools icon"
          width={24}
          height={24}
          src={MCUXpressoConfigToolsIcon.src}
        />
      ),
      target: "_blank",
    },
    {
      url: "/select-config-tools-data",
      title: t("data"),
      icon: <SimCardDownloadIcon />,
      offset: true,
    },
  ];

  const internalLinks: DrawerLinkType[] = [
    {
      url: "/deployed-releases",
      title: t("deployedReleases"),
      icon: <InfoTwoToneIcon />,
    },
    {
      url: "/hardware-in-releases",
      title: t("hardwareInReleases"),
      icon: <QuestionMarkTwoToneIcon />,
    },
    {
      url: "/analytics",
      title: t("analytics"),
      icon: <AnalyticsTwoToneIcon />,
    },
    {
      url: "/filtering-criteria-links",
      title: t("criteriaLinks"),
      icon: <LinkTwoToneIcon />,
    },
    {
      url: "/backdoor",
      title: t("backdoor"),
      icon: <MeetingRoomTwoTone />,
    },
  ];

  const exploreLinks: DrawerLinkType[] = [];

  if (!!auth.user?.ebhub) {
    exploreLinks.push({
      url: "/eb-hub",
      title: t("ebHub"),
      icon: (
        <Image alt="EB hub icon" width={24} height={24} src={EbhubIcon.src} />
      ),
      target: "_blank",
      ignoreLocale: true,
    });
  }

  if (!!auth.user?.appcodehub) {
    exploreLinks.push({
      url: "/appcodehub",
      title: t("acHub"),
      icon: (
        <Image
          alt="Application Code Hub icon"
          width={24}
          height={24}
          src={ACHIcon.src}
        />
      ),
      target: "_blank",
      ignoreLocale: true,
    });
  }

  return (
    <DrawerBase
      variant="permanent"
      anchor={"left"}
      sx={styles.drawer(DRAWER_WIDTH, open)}
      open={open}
      PaperProps={{ sx: styles.drawerPaper }}
    >
      <Box sx={styles.drawerMenu}>
        <Toolbar variant={"dense"} />
        {Object.values(topMessage).some(Boolean) && (
          <Toolbar variant={"dense"} />
        )}
        <Box sx={styles.sectionOuterContainer}>
          <Box>
            <DrawerSection open={open} links={buildSDKLinks} />
            {!!exploreLinks.length && (
              <DrawerSection
                open={open}
                title={t("explore")}
                links={exploreLinks}
              />
            )}
            <DrawerSection
              open={open}
              title={t("downloads")}
              links={downloadsLinks}
            />
            {auth.user?.hasInternalAccess && (
              <DrawerSection
                open={open}
                title={t("internal")}
                links={internalLinks}
                hideConditions={hideConditions}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={(theme) => styles.collapsibleSection(theme)}>
          <IconButton onClick={toggleCollapseHandler}>
            {open && <KeyboardDoubleArrowLeftTwoToneIcon />}
            {!open && <KeyboardDoubleArrowRightTwoToneIcon />}
          </IconButton>
        </Box>
      </Box>
    </DrawerBase>
  );
};

export default DrawerStyled;
