/*
 * Copyright 2023-2024 NXP
 */

import { CSSObject, styled, Theme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { DRAWER_WIDTH } from "@/src/components/layout/DrawerStyled/helpers";

const closedMixin = (theme: Theme): CSSObject => ({
    [theme.breakpoints.up('xs')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerBase = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: open ? DRAWER_WIDTH : '60px',
        flexShrink: 0,
        whiteSpace: open ? 'normal' : 'nowrap',
        boxSizing: 'border-box',
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default DrawerBase;