/*
 * Copyright 2023-2024 NXP
 */

"use client";
import React, { ReactNode } from "react";
import { UserQuery } from "@/web-builder/api";

type AuthContextType = {
  user: UserQuery | null;
};

export interface AuthContextProviderProps {
  user: UserQuery | null;
  children: ReactNode;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

const useAuthContext = () => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    return { user: null }; // unauthorized
  }

  return authContext;
};

const AuthContextProvider = ({ user, children }: AuthContextProviderProps) => {
  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthContext };
