/*
 * Copyright 2023-2024 NXP
 */

import { UAParser } from "ua-parser-js";

export const isBrowserUnsupported = () => {
  const userAgent = navigator.userAgent;
  const supportedBrowsers = [
    {
      name: "Chrome",
      version: 71,
    },
    {
      name: "Firefox",
      version: 78,
    },
    {
      name: "Edge",
      version: 18,
    },
    {
      name: "Safari",
      version: 14,
    },
  ];
  const parser = new UAParser(userAgent);
  const userBrowser = parser.getBrowser();

  const supportedBrowser = supportedBrowsers.find(
    (browser) => browser.name === userBrowser.name
  );

  return !!(
    supportedBrowser &&
    userBrowser.version &&
    supportedBrowser.version > parseInt(userBrowser.version)
  );
};
