/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";
import { Theme } from "@mui/material";

const styles = {
  listItemText: (open: boolean) => ({
    opacity: open ? 1 : 0,
  }),
  listItemIcon: (open: boolean) => ({
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
  }),
  listItemButton: (open: boolean, offset?: boolean) => ({
    paddingTop: open ? "3px" : "4px",
    paddingBottom: open ? "3px" : "4px",
    justifyContent: open ? "initial" : "center",
    pl: offset && open ? 4 : "default",
  }),
  divider: { margin: "10px 0" },
  drawerMenu: { overflowY: "auto", overflowX: "hidden" },
  collapsibleSection: (theme: Theme) => {
    return {
      display: "flex",
      alignItems: "center",
      position: "sticky",
      right: "0",
      bottom: 0,
      justifyContent: "flex-end",
      zIndex: theme.zIndex.drawer + 1,
      padding: theme.spacing(0, 1),
      backgroundColor: COLORS.grey[100],
    };
  },
  sectionOuterContainer: {
    marginTop: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    paddingBottom: "10px",
  },
  drawerPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subheader: { backgroundColor: COLORS.grey[100], paddingY: 0 },
  noPaddingBottom: { paddingBottom: 0 },
  noPaddingTop: { paddingTop: 0 },
  drawer: (drawerWidth: number, open: boolean) => {
    return {
      flexShrink: 0,
      whiteSpace: open ? "normal" : "nowrap",
      boxSizing: "border-box",
      width: drawerWidth,
      [`& .MuiDrawer-paper`]: {
        width: open ? drawerWidth : "60px",
        boxSizing: "border-box",
        backgroundColor: COLORS.grey[100],
      },
      ...(open
        ? {
            ["& .MuiTypography-root"]: {
              fontSize: "13px",
            },
          }
        : {}),
    };
  },
};

export default styles;
