/*
 * Copyright 2023-2024 NXP
 */

import {
  FilteringCriteriaType,
  HwCountType,
  Maybe,
  TopAlert,
  useTopAlertQuery,
} from "@/web-builder/api";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { WorkflowsKeys } from "@/src/lib/types";
import { isBrowserUnsupported } from "@/src/components/layout/TopMessage/helpers";

export interface CriteriaContext {
  hwCount: HwCountType;
  hwCountCalled: boolean;
  filters: FilteringCriteriaType;
  areOtherWorkflowsActive: (workflow?: WorkflowsKeys) => boolean;
  isWorkflowActive: (criteriaKey: WorkflowsKeys) => boolean;
  exampleLimit?: number;
  supportedMiddleware?: number[];
  setLocalCriteria: (
    count: HwCountType | null,
    newFilters: FilteringCriteriaType,
  ) => void;
  getLocalCriteria: () => any;
}

type ConfigToolsData = {
  name: String;
  toolUrlSuffix: String;
};

type GlobalContextType = {
  topMessage: {
    topAlert: Maybe<TopAlert>;
    unsupportedBrowser: boolean;
  };
  criteria: CriteriaContext;
  temporaryConfiguration: {
    hasTempConf: boolean;
    checkedTempConf: boolean;
    setHasTempConf: Dispatch<SetStateAction<boolean>>;
    setCheckedTempConf: Dispatch<SetStateAction<boolean>>;
  };
  configTools: {
    toolsData: ConfigToolsData | undefined;
    setToolsData: Dispatch<SetStateAction<ConfigToolsData | undefined>>;
  };
};

interface GlobalContextProviderProps {
  children: ReactNode;
}

export const defaultCriteria = {
  hwCount: { board: 0, kit: 0, device: 0 },
  hwCountCalled: false,
  filters: {
    hw: null,
    middleware: [],
    examples: [],
    toolchain: null,
    parametric: null,
    latestUpdate: null,
  },
};

const GlobalContext = createContext<GlobalContextType | null>(null);

const useGlobalContext = () => {
  const globalContext = React.useContext(GlobalContext);
  if (!globalContext) {
    throw new Error(
      "Cannot use `useGlobalContext` outside of a GlobalContextProvider",
    );
  }

  return globalContext;
};

const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const [hasTempConf, setHasTempConf] = useState(false);
  const [checkedTempConf, setCheckedTempConf] = useState(true);
  const [toolsData, setToolsData] = useState<ConfigToolsData>();
  const [hwCount, setHwCount] = useState<HwCountType>(defaultCriteria.hwCount);
  const [hwCountCalled, setHwCountCalled] = useState(false);
  const [unsupportedBrowser, setUnsupportedBrowser] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilteringCriteriaType>(
    defaultCriteria.filters,
  );

  const setLocalCriteria = (
    count: HwCountType | null,
    newFilters: FilteringCriteriaType,
  ) => {
    window.sessionStorage.setItem("localCriteria", JSON.stringify(newFilters));
    setFilters(newFilters);
    if (count) setHwCount(count);
    setHwCountCalled(true);
  };

  const getLocalCriteria = () => {
    const localCriteria = window.sessionStorage.getItem("localCriteria");
    if (!localCriteria) return filters;
    return JSON.parse(localCriteria);
  };

  const isWorkflowActive = (criteriaKey: WorkflowsKeys) => {
    const { examples, middleware, toolchain, parametric, hw } = filters;

    switch (criteriaKey) {
      case "middleware":
        return !!middleware?.length;
      case "hw":
        return !!hw;
      case "parametric":
        return !!parametric;
      case "examples":
        return !!examples?.length;
      case "toolchain":
        return !!toolchain;
      default:
        throw "invalid parameter";
    }
  };

  const areOtherWorkflowsActive = (workflow?: WorkflowsKeys) => {
    const hasExamples = isWorkflowActive("examples");
    const hasMiddleware = isWorkflowActive("middleware");
    const hasToolchain = isWorkflowActive("toolchain");
    const hasParametrics = isWorkflowActive("parametric");
    const hasHardware = isWorkflowActive("hw");

    switch (workflow) {
      case "middleware":
        return hasExamples || hasToolchain || hasParametrics || hasHardware;
      case "parametric":
        return hasMiddleware || hasExamples || hasToolchain || hasHardware;
      case "examples":
        return hasHardware || hasMiddleware || hasToolchain || hasParametrics;
      case "toolchain":
        return hasExamples || hasParametrics || hasMiddleware || hasHardware;
      default:
        return (
          hasExamples ||
          hasToolchain ||
          hasMiddleware ||
          hasParametrics ||
          hasHardware
        );
    }
  };

  useEffect(() => {
    setUnsupportedBrowser(isBrowserUnsupported());
  }, []);

  const topMessage = {
    topAlert: useTopAlertQuery().data?.topAlert,
    unsupportedBrowser,
  };

  const criteria = {
    hwCount,
    filters,
    hwCountCalled,
    areOtherWorkflowsActive,
    isWorkflowActive,
    setLocalCriteria,
    getLocalCriteria,
  };

  const temporaryConfiguration = {
    hasTempConf,
    setHasTempConf,
    checkedTempConf: checkedTempConf,
    setCheckedTempConf: setCheckedTempConf,
  };

  const configTools = {
    toolsData: toolsData,
    setToolsData: setToolsData,
  };

  return (
    <GlobalContext.Provider
      value={{
        topMessage,
        criteria,
        temporaryConfiguration,
        configTools,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, useGlobalContext };
