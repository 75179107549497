/*
 * Copyright 2023-2024 NXP
 */

import React from "react";
import List from "@mui/material/List";
import { Badge, Divider, ListSubheader, SxProps, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "@/src/components/layout/DrawerStyled/styles";
import { DrawerLinkType } from "@/src/components/layout/DrawerStyled/DrawerStyled";
import { useGlobalContext } from "@/src/contexts/GlobalContext";
import { usePathname } from "@/src/navigation";
import LinkWithLocale from "../../UI/LinkWithLocale/LinkWithLocale";

interface DrawerSectionProps {
  title?: string;
  links: DrawerLinkType[];
  open: boolean;
  sx?: SxProps;
  hideConditions?: any;
}

const DrawerSection = ({
  title,
  links,
  open,
  sx,
  hideConditions = {},
}: DrawerSectionProps) => {
  const pathName = usePathname();

  const { isWorkflowActive } = useGlobalContext().criteria;
  let subheader = null;
  if (title && open) {
    subheader = <ListSubheader sx={styles.subheader}>{title}</ListSubheader>;
  } else if (title) {
    subheader = <Divider sx={styles.divider} />;
  }

  const linkList = links.map((link) => {
    if (hideConditions?.[link.url]) return null;

    return (
      <Tooltip title={!open && link.title} placement={"right"} key={link.title}>
        <ListItem disablePadding>
          <ListItemButton
            href={link.url}
            target={link.target ?? "_self"}
            sx={[styles.listItemButton(open, link.offset)]}
            selected={link.url === pathName}
            LinkComponent={LinkWithLocale}
          >
            <ListItemIcon sx={styles.listItemIcon(open)}>
              <Badge
                color="success"
                overlap="circular"
                variant={"dot"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                invisible={!link.active}
              >
                {link.icon}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={styles.listItemText(open)} primary={link.title} />
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  });

  return (
    <List
      dense
      subheader={subheader}
      component={"nav"}
      sx={{ ...styles.noPaddingBottom, ...sx }}
    >
      {linkList}
    </List>
  );
};

export default DrawerSection;
